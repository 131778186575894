import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { ToastContainer } from 'react-toastify';

import RelayEnvironment from '@relate/relay/environment';

import './styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';

function CustomApp({ Component, pageProps, err }: AppProps & { err?: Error }) {
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/faviconWhiteBGCircle.png" />
        <title>[BuildUs] Admin</title>
      </Head>
      <div className="app">
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <main>
            <Component {...pageProps} err={err} />
            <ToastContainer />
          </main>
        </RelayEnvironmentProvider>
      </div>
    </>
  );
}

export default CustomApp;
