import { Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';

import { backendUrl } from './utils';

export const REFRESH_TOKEN = `mutation RefreshToken($refreshToken: String!) {
  refreshToken(input: { refreshToken: $refreshToken }) {
    token
    payload
  }
}`;

export const apiRefreshToken = (
  refreshToken: string
): Observable<AjaxResponse> =>
  ajax.post(
    backendUrl('graphql/'),
    {
      query: REFRESH_TOKEN,
      variables: {
        refreshToken,
      },
    },
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  );
