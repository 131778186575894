import { Session, User } from './types';

export function getUserFromSession(session: Session) {
  return session.user;
}

export const saveSession = async (
  user: User,
  token: string,
  refreshToken: string,
  isAuthenticated?: boolean
): Promise<Session> => {
  const session: Session = {
    user,
    token,
    refreshToken,
    isAuthenticated,
  };
  localStorage.setItem('session', JSON.stringify(session));
  return session;
};

export const loadSession = (): Session | undefined => {
  let session = null;
  if ('browser' in process) {
    const sessionJson = localStorage.getItem('session');
    if (sessionJson) {
      session = JSON.parse(sessionJson);
    }
  }
  return session;
};

export const deleteSession = (): void => {
  localStorage.removeItem('session');
};
